const FirebaseConfig = {
  apiKey: "AIzaSyDl-902pCBGq1aSWY73VYkC4FBV3qblHdo",
  authDomain: "mybiz-app-6b543.firebaseapp.com",
  databaseURL: "https://mybiz-app-6b543-default-rtdb.firebaseio.com",
  projectId: "mybiz-app-6b543",
  storageBucket: "mybiz-app-6b543.appspot.com",
  messagingSenderId: "969829164668",
  appId: "1:969829164668:web:1d6ef79b622ca7c685cbf9",
  measurementId: "G-53XKY8JEP4"
};

export default FirebaseConfig;
