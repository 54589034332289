import {
    GET_PROBLEM_INIT,
    GET_PROBLEM_SUCCESS,
    GET_PROBLEM_ERROR,
    SET_PROBLEM_REQUEST_ACCEPT_INIT,
    SET_PROBLEM_REQUEST_ACCEPT_SUCCESS,
    SET_PROBLEM_REQUEST_ACCEPT_ERROR,
    SET_PROBLEM_REQUEST_REJECT_INIT,
    SET_PROBLEM_REQUEST_REJECT_SUCCESS,
    SET_PROBLEM_REQUEST_REJECT_ERROR,
    SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_INIT,
    SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_SUCCESS,
    SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_ERROR,

    GET_PROBLEM_ADVISOR_INIT,
    GET_PROBLEM_ADVISOR_SUCCESS,
    GET_PROBLEM_ADVISOR_ERROR,

    SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_INIT,
    SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_SUCCESS,
    SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_ERROR,

    SET_PROBLEM_ADVISOR_REQUEST_REJECT_INIT,
    SET_PROBLEM_ADVISOR_REQUEST_REJECT_SUCCESS,
    SET_PROBLEM_ADVISOR_REQUEST_REJECT_ERROR,

    SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_INIT,
    SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_SUCCESS,
    SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_ERROR,
  } from "../constants/ProblemConstants.js";
  
  const initialState = {
    data: [],
    problemAdvisorList:[],
    loading: false,
    //problemAdvisorListLoading:false,
    error: null,
    problemAdvisorError:false,
    success: false,
    problemAdvisorSuccess:false,
    delete: false,
  };
  
  const problem = (state = initialState, action) => {
    switch (action.type) {
      case GET_PROBLEM_INIT:
        return { ...state, loading: true };
      case GET_PROBLEM_SUCCESS:
        return { ...state, success: true, data: action.data, loading: false };
      case GET_PROBLEM_ERROR:
        return { ...state, error: action.error, loading: false };

        case SET_PROBLEM_REQUEST_ACCEPT_INIT:
      return { ...state, loading: true };
    case SET_PROBLEM_REQUEST_ACCEPT_SUCCESS:
      window.location.reload();
      // let objIndex = state.data.findIndex((obj) => obj.id === action.data.id);
      // let newArray = state.data;
      // newArray[objIndex].status = "complete";
      console.log(action.data);
      const { id, referenceIdRef, selectedDateRef, descriptionRef } = action.data;
     console.log("test" +referenceIdRef);
      
      const updatedData = state.data.map(item => {
        if (item.id === id) {
          return {
            ...item,
            
            paymentRefId:referenceIdRef, 
            paymentDate:selectedDateRef,
            acceptedDescription:descriptionRef,
            status:"Accepted"
          };
        }
        return item;
      });
      console.log("Updated Data:", updatedData);
      
      return { ...state, success: true, data: updatedData, loading: false };
    case SET_PROBLEM_REQUEST_ACCEPT_ERROR:
      return { ...state, error: action.error, loading: false };

      case SET_PROBLEM_REQUEST_REJECT_INIT:
      return { ...state, loading: true };
    case SET_PROBLEM_REQUEST_REJECT_SUCCESS:
      window.location.reload();
      // let objIndex = state.data.findIndex((obj) => obj.id === action.data.id);
      // let newArray = state.data;
      // newArray[objIndex].status = "complete";
     
      const { id:rejectId,descriptionRef1 } = action.data;
     //console.log("test" +referenceIdRef);
      
      const updatedData1 = state.data.map(item => {
        if (item.id === rejectId) {
          return {
            ...item,
            rejectedDescription:descriptionRef1,
            status:"Rejected"
          };
        }
        return item;
      });
      console.log("Updated Data:", updatedData1);
      
      return { ...state, success: true, data: updatedData1, loading: false };
    case SET_PROBLEM_REQUEST_REJECT_ERROR:
      return { ...state, error: action.error, loading: false };


      case SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_INIT:
        return { ...state, loading: true };
      case SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_SUCCESS:
        window.location.reload();
        // let objIndex = state.data.findIndex((obj) => obj.id === action.data.id);
        // let newArray = state.data;
        // newArray[objIndex].status = "complete";
       
        const { id:rejectinvoiceId,descriptionRef2 } = action.data;
     //console.log("test" +referenceIdRef);
      
      const updatedData2 = state.data.map(item => {
     
        return item;
      });
      console.log("Updated Data:", updatedData2);
        
        return { ...state, success: true, data: updatedData2, loading: false };
      case SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_ERROR:
        return { ...state, error: action.error, loading: false };
      
        case GET_PROBLEM_ADVISOR_INIT:
          return { ...state, loading: true };
        case GET_PROBLEM_ADVISOR_SUCCESS:
          return {
            ...state,
            problemAdvisorSuccess: true,
            problemAdvisorList: action.data,
            loading: false,
          };
         // return { ...state, success: true, data: action.data, problemAdvisorListLoading: false };
        case GET_PROBLEM_ADVISOR_ERROR:
          return {
            ...state,
            problemAdvisorError: action.problemAdvisorError,
            loading: false,
          };
        

          case SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_INIT:
      return { ...state, loading: true };
    case SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_SUCCESS:
      window.location.reload();
      // let objIndex = state.data.findIndex((obj) => obj.id === action.data.id);
      // let newArray = state.data;
      // newArray[objIndex].status = "complete";
     
      const { id:pblmId, descriptionAdvisor1 } = action.data;
     //console.log("test" +referenceIdRef);
      
      const updatedAdvisorData = state.data.map(item => {
        if (item.id === pblmId) {
          return {
            ...item,
            acceptedDescription:descriptionRef,
            status:"Accepted"
          };
        }
        return item;
      });
      console.log("Updated Data:", updatedAdvisorData);
      
      return { ...state, success: true, data: updatedAdvisorData, loading: false };
    case SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_ERROR:
      return { ...state, error: action.error, loading: false };

     
      case SET_PROBLEM_ADVISOR_REQUEST_REJECT_INIT:
      return { ...state, loading: true };
    case SET_PROBLEM_ADVISOR_REQUEST_REJECT_SUCCESS:
      window.location.reload();
      // let objIndex = state.data.findIndex((obj) => obj.id === action.data.id);
      // let newArray = state.data;
      // newArray[objIndex].status = "complete";
     
      const { id:rejectId1,descriptionRef4 } = action.data;
     //console.log("test" +referenceIdRef);
      
      const updatedData4 = state.data.map(item => {
        if (item.id === rejectId1) {
          return {
            ...item,
            rejectedDescription:descriptionRef2,
            status:"Rejected"
          };
        }
        return item;
      });
      console.log("Updated Data:", updatedData4);
      
      return { ...state, success: true, data: updatedData4, loading: false };
    case SET_PROBLEM_ADVISOR_REQUEST_REJECT_ERROR:
      return { ...state, error: action.error, loading: false };

      case SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_INIT:
        return { ...state, loading: true };
      case SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_SUCCESS:
        window.location.reload();
        // let objIndex = state.data.findIndex((obj) => obj.id === action.data.id);
        // let newArray = state.data;
        // newArray[objIndex].status = "complete";
       
        const { id:rejectinvoiceId5,descriptionRef5 } = action.data;
     //console.log("test" +referenceIdRef);
      
      const updatedData9 = state.data.map(item => {
     
        return item;
      });
      console.log("Updated Data:", updatedData9);
        
        return { ...state, success: true, data: updatedData9, loading: false };
      case SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_ERROR:
        return { ...state, error: action.error, loading: false };
      //plopImport
      default:
        return state;
    }
  };
  
  export default problem;
  