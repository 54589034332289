import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  getProblemError,
  getProblemSuccess,
  setProblemRequestAcceptActionSuccess,
  setProblemRequestAcceptActionError,
  setProblemRequestRejectActionSuccess,
  setProblemRequestRejectActionError,
  setProblemRequestRejectWithInvoiceActionSuccess,
  setProblemRequestRejectWithInvoiceActionError,
  getProblemAdvisorSuccess,
  getProblemAdvisorError,
  setProblemAdvisorRequestAcceptActionSuccess,
  setProblemAdvisorRequestAcceptActionError,
  setProblemAdvisorRequestRejectActionSuccess,
  setProblemAdvisorRequestRejectActionError,
  setProblemAdvisorRequestAcceptWithInvoiceActionSuccess,
  setProblemAdvisorRequestAcceptWithInvoiceActionError,
  //plopImportAction
} from "../actions/ProblemActions.js";
import {
  GET_PROBLEM_INIT,
  SET_PROBLEM_REQUEST_ACCEPT_INIT,
  SET_PROBLEM_REQUEST_REJECT_INIT,
  SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_INIT,
  GET_PROBLEM_ADVISOR_INIT,
  SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_INIT,
  SET_PROBLEM_ADVISOR_REQUEST_REJECT_INIT,
  SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_INIT
  //plopImportConstant
} from "redux/constants/ProblemConstants.js";

import FirebaseService from "services/FirebaseService";

export function* GetProblem() {
  yield takeEvery(GET_PROBLEM_INIT, function* () {
    try {
      const data = yield call(FirebaseService.fetchCollection, "Problem")
       // Manual sorting by createdAt in descending order
      const sortedData = data.sort((a, b) => {
        const aSeconds = a.call_time ? a.call_time.seconds : 0;
        const bSeconds = b.call_time ? b.call_time.seconds : 0;
        return bSeconds - aSeconds;
      });

      // Manual filtering for accountStatus === "nonactive"
      const filteredData = sortedData.filter(element => element.app === "Owner");

      let changeTime = filteredData.map((element) => {
        var d = new Date(0);
        if (element.call_time && element.call_time.seconds) {
          d.setUTCSeconds(element.call_time.seconds);
        }
        return {
          ...element,
          call_time: `${d}`,
        };
      });

      console.log(
        "🚀 ~ file: ProblemSaga.js ~ line 22 ~ changeTime ~ changeTime",
        changeTime
      );

      yield put(getProblemSuccess(changeTime));
    } catch (error) {
      console.log(
        "🚀 ~ file: ProblemSaga.js ~ line 41 ~ yieldtakeEvery ~ error",
        error.message
      );
      yield put(getProblemError(error));
    }
  });
}
export function* setProblemAcceptComplete() {
  yield takeEvery(SET_PROBLEM_REQUEST_ACCEPT_INIT, function* (action) {
    try {
      yield call(FirebaseService.markProblemAcceptRequestComplete, action.data);
      yield put(setProblemRequestAcceptActionSuccess(action.data));
    } catch (error) {
      yield put(setProblemRequestAcceptActionError(error));
    }
  });
}

export function* setProblemRejectComplete() {
  yield takeEvery(SET_PROBLEM_REQUEST_REJECT_INIT, function* (action) {
    try {
      yield call(FirebaseService.markProblemRejectRequestComplete, action.data);
      yield put(setProblemRequestRejectActionSuccess(action.data));
    } catch (error) {
      yield put(setProblemRequestRejectActionError(error));
    }
  });
}

export function* setProblemRejectWithInvoiceComplete() {
  yield takeEvery(SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_INIT, function* (action) {
    try {
      yield call(FirebaseService.markProblemRejectWithInvoiceRequestComplete, action.data);
      yield put(setProblemRequestRejectWithInvoiceActionSuccess(action.data));
    } catch (error) {
      yield put(setProblemRequestRejectWithInvoiceActionError(error));
    }
  });
}


export function* GetProblemAdvisor() {
  yield takeEvery(GET_PROBLEM_ADVISOR_INIT, function* () {
    try {
      const data = yield call(FirebaseService.fetchCollection, "Problem")
       // Manual sorting by createdAt in descending order
      const sortedData = data.sort((a, b) => {
        const aSeconds = a.call_time ? a.call_time.seconds : 0;
        const bSeconds = b.call_time ? b.call_time.seconds : 0;
        return bSeconds - aSeconds;
      });

      // Manual filtering for accountStatus === "nonactive"
      const filteredData = sortedData.filter(element => element.app === "Advisor");

      let changeTime = filteredData.map((element) => {
        var d = new Date(0);
        if (element.call_time && element.call_time.seconds) {
          d.setUTCSeconds(element.call_time.seconds);
        }
        return {
          ...element,
          call_time: `${d}`,
        };
      });

      console.log(
        "🚀 ~ file: AdvisorProblemSaga.js ~ line 22 ~ changeTime ~ changeTime",
        changeTime
      );

      yield put(getProblemAdvisorSuccess(changeTime));
    } catch (error) {
      console.log(
        "🚀 ~ file: AdvisorProblemSaga.js ~ line 41 ~ yieldtakeEvery ~ error",
        error.message
      );
      yield put(getProblemAdvisorError(error));
    }
  });
}

export function* setProblemAdvisorAcceptComplete() {
  yield takeEvery(SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_INIT, function* (action) {
    try {
      yield call(FirebaseService.markProblemAdvisorAcceptRequestComplete, action.data);
      yield put(setProblemAdvisorRequestAcceptActionSuccess(action.data));
    } catch (error) {
      yield put(setProblemAdvisorRequestAcceptActionError(error));
    }
  });
}

export function* setProblemAdvisorRejectComplete() {
  yield takeEvery(SET_PROBLEM_ADVISOR_REQUEST_REJECT_INIT, function* (action) {
    try {
      yield call(FirebaseService.markProblemAdvisorRejectRequestComplete, action.data);
      yield put(setProblemAdvisorRequestRejectActionSuccess(action.data));
    } catch (error) {
      yield put(setProblemAdvisorRequestRejectActionError(error));
    }
  });
}

export function* setProblemAdvisorAcceptWithInvoiceComplete() {
  yield takeEvery(SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_INIT, function* (action) {
    try {
      yield call(FirebaseService.markProblemAdvisorAcceptWithInvoiceRequestComplete, action.data);
      yield put(setProblemAdvisorRequestAcceptWithInvoiceActionSuccess(action.data));
    } catch (error) {
      yield put(setProblemAdvisorRequestAcceptWithInvoiceActionError(error));
    }
  });
}
//plopSaga
export default function* rootSaga() {
  yield all([
    fork(GetProblem),
    fork(setProblemAcceptComplete),
    fork(setProblemRejectComplete),
    fork(setProblemRejectWithInvoiceComplete),
    fork(GetProblemAdvisor),
    fork(setProblemAdvisorAcceptComplete),
    fork(setProblemAdvisorRejectComplete),
    fork(setProblemAdvisorAcceptWithInvoiceComplete),
    //plopExport
  ]);
}
