import {
    GET_BIZ_INVOICE_INIT,
    GET_BIZ_INVOICE_SUCCESS,
    GET_BIZ_INVOICE_ERROR

} from '../constants/BizInvoiceConstants.js';
export const getBizInvoiceInit = () => ({
    type: GET_BIZ_INVOICE_INIT,
});

export const getBizInvoiceSuccess = (data) => ({
    type: GET_BIZ_INVOICE_SUCCESS,
    data
});

export const getBizInvoiceError = (error) => ({
    type: GET_BIZ_INVOICE_ERROR,
    error
});