import {
  UPDATE_APP_TEXT_ADVISOR_INIT,
  UPDATE_APP_TEXT_ADVISOR_SUCCESS,
  UPDATE_APP_TEXT_ADVISOR_ERROR,
  GET_APP_TEXT_ADVISOR_INIT,
  GET_APP_TEXT_ADVISOR_SUCCESS,
  GET_APP_TEXT_ADVISOR_ERROR,
  UPDATE_APP_TEXT_INIT,
  UPDATE_APP_TEXT_SUCCESS,
  UPDATE_APP_TEXT_ERROR,
  GET_LANGUAGE_INIT,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGE_ERROR,
  UPDATE_TERMS_AND_CONDITION_INIT,
  UPDATE_TERMS_AND_CONDITION_SUCCESS,
  UPDATE_TERMS_AND_CONDITION_ERROR,
  GET_TERMS_AND_CONDITIONS_INIT,
  GET_TERMS_AND_CONDITIONS_SUCCESS,
  GET_TERMS_AND_CONDITIONS_ERROR,
  DELETE_FAQ_INIT,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_ERROR,
  GET_FAQ_INIT,
  GET_FAQ_SUCCESS,
  GET_FAQ_ERROR,
  ADD_FAQ_INIT,
  ADD_FAQ_SUCCESS,
  ADD_FAQ_ERROR,
  DELETE_IMAGE_CAROUSEL_INIT,
  DELETE_IMAGE_CAROUSEL_SUCCESS,
  DELETE_IMAGE_CAROUSEL_ERROR,
  GET_IMAGE_CAROUSEL_INIT,
  GET_IMAGE_CAROUSEL_SUCCESS,
  GET_IMAGE_CAROUSEL_ERROR,
  SAVE_IMAGE_CAROUSEL_INIT,
  SAVE_IMAGE_CAROUSEL_SUCCESS,
  SAVE_IMAGE_CAROUSEL_ERROR,
  GET_WITHDRAWAL_SETTINGS_INIT,
  GET_WITHDRAWAL_SETTINGS_SUCCESS,
  GET_WITHDRAWAL_SETTINGS_ERROR,
  SET_WITHDRAWAL_PERCENTAGE_INIT,
  SET_WITHDRAWAL_PERCENTAGE_SUCCESS,
  SET_WITHDRAWAL_PERCENTAGE_ERROR,
  UPDATE_PRIVACY_POLICY_INIT,
  UPDATE_PRIVACY_POLICY_SUCCESS,
  UPDATE_PRIVACY_POLICY_ERROR,
  GET_PRIVACY_POLICYS_INIT,
  GET_PRIVACY_POLICYS_SUCCESS,
  GET_PRIVACY_POLICYS_ERROR,
  GET_RAZORPAY_SETTINGS_INIT,
  GET_RAZORPAY_SETTINGS_SUCCESS,
  GET_RAZORPAY_SETTINGS_ERROR,
  SET_RAZORPAY_SETTINGS_INIT,
  SET_RAZORPAY_SETTINGS_SUCCESS,
  SET_RAZORPAY_SETTINGS_ERROR,

  GET_INSTAMOJO_SETTINGS_INIT,
  GET_INSTAMOJO_SETTINGS_SUCCESS,
  GET_INSTAMOJO_SETTINGS_ERROR,
  SET_INSTAMOJO_SETTINGS_INIT,
  SET_INSTAMOJO_SETTINGS_SUCCESS,
  SET_INSTAMOJO_SETTINGS_ERROR,
} from "../constants/SettingsConstants.js";

const initialState = {
  data: [],
  faq: [],
  language: [],
  textAdvisors: [],
  termsAndConditions: [],
  privacyPolicy: [],
  loading: false,
  error: null,
  successGet: false,
  delete: false,
  success: false,
  imageCarouselData: [],
  getImageCarouselLoading: false,
  getImageCarouselError: null,
  getImageCarouselSuccess: false,
  saveImageCarouselLoading: false,
  saveImageCarouselError: null,
  saveImageCarouselSuccess: false,
  deleteImageCarouselSuccess: false,
  deleteImageCarouselError: null,
  addFaqSuccess: false,
  addFaqError: null,
  faqLoading: false,
  deleteFaqSuccess: false,
  delteFaqError: null,
  termsAndConditionsLoading: false,
  privacyPolicysLoading: false,
  successMessage: "",

  getRazorpaySettingsLoading: false,
  getRazorpaySettingsError: null,
  getRazorpaySettingsSuccess: false,

  getInstamojoSettingsLoading: false,
  getInstamojoSettingsError: null,
  getInstamojoSettingsSuccess: false,
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case SET_WITHDRAWAL_PERCENTAGE_INIT:
      return { ...state, loading: true };
    case SET_WITHDRAWAL_PERCENTAGE_SUCCESS:
      return { ...state, success: true, data: action.data, loading: false };
    case SET_WITHDRAWAL_PERCENTAGE_ERROR:
      return { ...state, error: action.error, loading: false };
    case GET_WITHDRAWAL_SETTINGS_INIT:
      return { ...state, loading: true };
    case GET_WITHDRAWAL_SETTINGS_SUCCESS:
      return { ...state, successGet: true, data: action.data, loading: false };
    case GET_WITHDRAWAL_SETTINGS_ERROR:
      return { ...state, error: action.error, loading: false };
    case SAVE_IMAGE_CAROUSEL_INIT:
      return { ...state, saveImageCarouselLoading: true };
    case SAVE_IMAGE_CAROUSEL_SUCCESS:
      return {
        ...state,
        saveImageCarouselSuccess: true,
        saveImageData: action.data,
        saveImageCarouselLoading: false,

        successMessage: "Success Save Image Caraousel",
      };
    case SAVE_IMAGE_CAROUSEL_ERROR:
      return {
        ...state,
        saveImageCarouselError: action.error,
        saveImageCarouselLoading: false,
      };
    case GET_IMAGE_CAROUSEL_INIT:
      return {
        ...state,
        getImageCarouselLoading: true,
        saveImageCarouselSuccess: false,
      };
    case GET_IMAGE_CAROUSEL_SUCCESS:
      return {
        ...state,
        imageCarouselData: action.data,
        getImageCarouselSuccess: true,
        getImageCarouselLoading: false,
      };
    case GET_IMAGE_CAROUSEL_ERROR:
      return {
        ...state,
        getImageCarouselError: action.error,
        getImageCarouselLoading: false,
      };
    case DELETE_IMAGE_CAROUSEL_INIT:
      return { ...state, getImageCarouselLoading: true };
    case DELETE_IMAGE_CAROUSEL_SUCCESS:
      console.log("action image id : " + JSON.stringify(action.data.imageId));
      return {
        ...state,
        deleteImageCarouselSuccess: true,
        imageCarouselData: state.imageCarouselData.filter(
          (elem) => elem.id !== action.data.imageId
        ),
        getImageCarouselLoading: false,

        successMessage: "Success Delete Image Caraousel",
      };
    case DELETE_IMAGE_CAROUSEL_ERROR:
      return {
        ...state,
        deleteImageCarouselError: action.error,
        getImageCarouselLoading: false,
      };
    case ADD_FAQ_INIT:
      return { ...state, loading: true };
    case ADD_FAQ_SUCCESS:
      return {
        ...state,
        success: true,
        faq: [...state.faq, action.data],
        loading: false,
        successMessage: "Success add new FAQ",
      };
    case ADD_FAQ_ERROR:
      return { ...state, error: action.error, faqLoading: false };
    case GET_FAQ_INIT:
      return { ...state, faqLoading: true };
    case GET_FAQ_SUCCESS:
      return { ...state, faq: action.data, faqLoading: false };
    case GET_FAQ_ERROR:
      return { ...state, error: action.error, faqLoading: false };
    case DELETE_FAQ_INIT:
      return { ...state, faqLoading: true };
    case DELETE_FAQ_SUCCESS:
      console.log(
        "🚀 ~ file: SettingsReducer.js ~ line 134 ~ settings ~ action",
        action.data
      );
      return {
        ...state,
        success: true,
        faq: state.faq.filter((faq) => faq.id !== action.data.id),
        faqLoading: false,
        successMessage: "Success delete faq",
      };
    case DELETE_FAQ_ERROR:
      return { ...state, error: action.error, faqLoading: false };
    case GET_TERMS_AND_CONDITIONS_INIT:
      return { ...state, termsAndConditionsLoading: true };
    case GET_TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        success: true,
        termsAndConditions: action.data,
        termsAndConditionsLoading: false,
      };
    case GET_TERMS_AND_CONDITIONS_ERROR:
      return { ...state, error: action.error, termsAndConditionsLoading: false };
    case UPDATE_TERMS_AND_CONDITION_INIT:
      return { ...state, termsAndConditionsLoading: true };
    case UPDATE_TERMS_AND_CONDITION_SUCCESS:
      return {
        ...state,
        success: true,
        termsAndConditionsLoading: false,
        successMessage: "Success update terms and conditions",
      };
    case UPDATE_TERMS_AND_CONDITION_ERROR:
      return { ...state, error: action.error, termsAndConditionsLoading: false };
    case GET_LANGUAGE_INIT:
      return { ...state, loading: true };
    case GET_LANGUAGE_SUCCESS:
      let result = Object.entries(action.data).map(([k, v]) => ({ [k]: v }));
      return { ...state, success: true, language: result, loading: false };
    case GET_LANGUAGE_ERROR:
      return { ...state, error: action.error, loading: false };
    case UPDATE_APP_TEXT_INIT:
      return { ...state, loading: true };
    case UPDATE_APP_TEXT_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        successMessage: "Success update text client app",
      };
    case UPDATE_APP_TEXT_ERROR:
      return { ...state, error: action.error, loading: false };
    case GET_APP_TEXT_ADVISOR_INIT:
      return { ...state, loading: true };
    case GET_APP_TEXT_ADVISOR_SUCCESS:
      let textAdvisorConvert = Object.entries(action.data).map(([k, v]) => ({
        [k]: v,
      }));
      return {
        ...state,
        success: true,
        textAdvisors: textAdvisorConvert,
        loading: false,
      };
    case GET_APP_TEXT_ADVISOR_ERROR:
      return { ...state, error: action.error, loading: false };
    case UPDATE_APP_TEXT_ADVISOR_INIT:
      return { ...state, loading: true };
    case UPDATE_APP_TEXT_ADVISOR_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        successMessage: "Success update text advisor app",
      };
    case UPDATE_APP_TEXT_ADVISOR_ERROR:
      return { ...state, error: action.error, loading: false };

      case GET_PRIVACY_POLICYS_INIT:
      return { ...state, privacyPolicysLoading: true };
    case GET_PRIVACY_POLICYS_SUCCESS:
      return {
        ...state,
        success: true,
        privacyPolicys: action.data,
        privacyPolicysLoading: false,
      };
    case GET_PRIVACY_POLICYS_ERROR:
      return { ...state, error: action.error, privacyPolicysLoading: false };
    case UPDATE_PRIVACY_POLICY_INIT:
      return { ...state, privacyPolicysLoading: true };
    case UPDATE_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        success: true,
        privacyPolicysLoading: true,
        successMessage: "Success privacy policy",
      };
    case UPDATE_PRIVACY_POLICY_ERROR:
      return { ...state, error: action.error, privacyPolicysLoading: false };


      case GET_INSTAMOJO_SETTINGS_INIT:
      return { ...state, getInstamojoSettingsLoading: true };
    case GET_INSTAMOJO_SETTINGS_SUCCESS:
      return { ...state, successGet: true, data: action.data, getInstamojoSettingsLoading: false };
    case GET_INSTAMOJO_SETTINGS_ERROR:
      return { ...state, error: action.error, getInstamojoSettingsLoading: false };


      case SET_INSTAMOJO_SETTINGS_INIT:
      return { ...state, loading: true };
    case SET_INSTAMOJO_SETTINGS_SUCCESS:
      return { ...state, success: true, data: action.data, getInstamojoSettingsLoading: false };
    case SET_INSTAMOJO_SETTINGS_ERROR:
      return { ...state, error: action.error, getInstamojoSettingsLoading: false };
    //plopImport
    default:
      return state;
  }
};

export default settings;
