import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Users from "./Users";
import DoctorCategory from "./DoctorCategory";
import Doctor from "./Doctor";
import InactiveDoctor from "./InactiveDoctor";
import WithdrawRequest from "./WithdrawRequestReducer";
import Transaction from "./TransactionReducer";
import Dashboard from "./DashboardReducer";
import Settings from "./SettingsReducer";
import ContactUs from "./ContactUsReducer";
import Invoice from "./InvoiceReducer";
import GstInvoice from "./GstInvoiceReducer";
import BizInvoice from "./BizInvoiceReducer";
import GstBizInvoice from "./GstBizInvoiceReducer";
import Problem from "./ProblemReducer";
import CancelledInvoice from "./CancelledInvoiceReducer";
//plopImport

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  users: Users,
  doctorCategory: DoctorCategory,
  doctor: Doctor,
  inactivedoctor: InactiveDoctor,
  withdrawRequest: WithdrawRequest,
  transaction: Transaction,
  dashboard: Dashboard,
  settings: Settings,
  contactUs: ContactUs,
  invoice: Invoice,
  gstinvoice:GstInvoice,
  bizinvoice: BizInvoice, 
  gstbizinvoice:GstBizInvoice,
  problem: Problem,
  cancelledinvoice:CancelledInvoice,
  //plopReducer
});

export default reducers;
