export const GET_PROBLEM_INIT = "GET_PROBLEM_INIT";
export const GET_PROBLEM_SUCCESS = "GET_PROBLEM_SUCCESS";
export const GET_PROBLEM_ERROR = "GET_PROBLEM_ERROR";
//plopImport

export const SET_PROBLEM_REQUEST_ACCEPT_INIT = "SET_PROBLEM_REQUEST_ACCEPT_INIT";
export const SET_PROBLEM_REQUEST_ACCEPT_SUCCESS = "SET_PROBLEM_REQUEST_ACCEPT_SUCCESS";
export const SET_PROBLEM_REQUEST_ACCEPT_ERROR = "SET_PROBLEM_REQUEST_ACCEPT_ERROR";

export const SET_PROBLEM_REQUEST_REJECT_INIT = "SET_PROBLEM_REQUEST_REJECT_INIT";
export const SET_PROBLEM_REQUEST_REJECT_SUCCESS = "SET_PROBLEM_REQUEST_REJECT_SUCCESS";
export const SET_PROBLEM_REQUEST_REJECT_ERROR = "SET_PROBLEM_REQUEST_REJECT_ERROR";

export const SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_INIT = "SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_INIT";
export const SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_SUCCESS = "SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_SUCCESS";
export const SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_ERROR = "SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_ERROR";

export const GET_PROBLEM_ADVISOR_INIT = "GET_PROBLEM_ADVISOR_INIT";
export const GET_PROBLEM_ADVISOR_SUCCESS = "GET_PROBLEM_ADVISOR_SUCCESS";
export const GET_PROBLEM_ADVISOR_ERROR = "GET_PROBLEM_ADVISOR_ERROR";

export const SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_INIT = "SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_INIT";
export const SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_SUCCESS = "SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_SUCCESS";
export const SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_ERROR = "SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_ERROR";

export const SET_PROBLEM_ADVISOR_REQUEST_REJECT_INIT = "SET_PROBLEM_ADVISOR_REQUEST_REJECT_INIT";
export const SET_PROBLEM_ADVISOR_REQUEST_REJECT_SUCCESS = "SET_PROBLEM_ADVISOR_REQUEST_REJECT_SUCCESS";
export const SET_PROBLEM_ADVISOR_REQUEST_REJECT_ERROR = "SET_PROBLEM_ADVISOR_REQUEST_REJECT_ERROR";

export const SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_INIT = "SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_INIT";
export const SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_SUCCESS = "SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_SUCCESS";
export const SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_ERROR = "SET_PROBLEM_ADVISOR_REQUEST_ACCEPT_WITH_INVOICE_ERROR";