import {
    GET_GST_BIZ_INVOICE_INIT,
    GET_GST_BIZ_INVOICE_SUCCESS,
    GET_GST_BIZ_INVOICE_ERROR

} from '../constants/GstBizInvoiceConstants.js';
export const getGstBizInvoiceInit = () => ({
    type: GET_GST_BIZ_INVOICE_INIT,
});

export const getGstBizInvoiceSuccess = (data) => ({
    type: GET_GST_BIZ_INVOICE_SUCCESS,
    data
});

export const getGstBizInvoiceError = (error) => ({
    type: GET_GST_BIZ_INVOICE_ERROR,
    error
});